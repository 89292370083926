import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Carousel, Row, Col, Card, Tag } from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import parse from "html-react-parser"
import { assets } from '../data';
import { Link } from "gatsby"
import SEO from '../components/seo';
import { useStaticQuery, graphql } from "gatsby"

const { Meta } = Card;

export default function Portfolio(props) {

  const { wpPage: {id, slug, content, title}, allWpPost: {posts} } = useStaticQuery(graphql`
  query PortfolioQuery {
    allWpPost(sort: { fields: [date], order: DESC }) {
      posts: edges {
        #previous {
        #  id
        #}

        # note: this is a GraphQL alias. It renames "node" to "post" for this query
        # We're doing this because this "node" is a post! It makes our code more readable further down the line.
        node {
          id
          uri
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
              uri
            }
          }
          content
          title
        }

        #next {
        #  id
        #}
      }
    }
    wpPage(slug: {eq: "portfolio"}) {
      id
      slug
      title
      content
    }
  }`)

  const getSliceText = (text) => {
    // console.log(text);
    return text.slice(0, 250)+'...'
  };
  const reduce = [] 
  const amount = props.isMobile ? 1 : 3;

  if (posts && posts.length > 0) {
    posts.forEach((x, i) => {
      if (i !== 0 && i % amount !== 0) reduce[reduce.length-1].push(x);
      else reduce.push([x]);
    });
  }

  const children =  reduce.map((item, i) => {
    return (<QueueAnim component={Row} type="bottom" key={i.toString()}>
      <div className="card-wrapper">
      {
        item.map((x, j) => {
          const { node: {featuredImage, author, content, title, uri, tags: {nodes: tags}} } = x;

          return (<Card
              key={`${i}_${j}`}
              hoverable
              style={{ width: props.isMobile ? '100%' : 350 }}
              cover={<img alt={featuredImage?.node?.altText || 'no image'} src={featuredImage?.node?.sourceUrl || 'noimage.png'} />}
            >
              <Meta className="card-meta" title={<Link to={uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>} description={getSliceText(parse(content)[1]?.props?.children || '')} />
              <div style={{textAlign: 'right', marginTop: 20}}>
                {tags.map(x => <Tag key={x.name}>{x.name}</Tag>)}
              </div>
              <div className="author">
                  <img className="user" src={author.node.avatar.url} />
                  <span>~{author.node.name}</span>
              </div>
            </Card>)
        })
      }</div></QueueAnim>)
  });

  return (
    <>
    {/* <SEO title={page.title} description="Portfolio kami yang beragam dari berbagai macam hasil kerja keras kami" /> */}
    <OverPack component="section" className="page-wrapper portfolio">
      {/* <Row className="slide-wrapper">
        <Col className="arrow" span={12}>
          <LeftOutlined className=""/>
        </Col>
        <Col className="arrow right" span={12}>
          <RightOutlined className=""/>
        </Col>
      </Row> */}
      <QueueAnim
        type="bottom"
        className="page text-center"
        leaveReverse
        key="a"
      >
        <h2 key="title">{parse(title)}</h2>
        <span key="line" className="separator" />
        <div key="1" className="carousel-wrapper">
          <Carousel effect={props.isMobile ? 'scrollx' : 'fade'} autoplay>
              {children}
          </Carousel>
        </div>
      </QueueAnim>
    </OverPack>
    </>)
}